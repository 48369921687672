import React from 'react'
import { useSelector } from 'react-redux'
import { Button, ProgressStep, ProgressSteps } from '@revolut/ui-kit'
import { useGetPerformanceCycleTimelineEvents } from '@src/api/performance'
import {
  PerformanceSelector,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { canAddTeamKpi, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getTimelineStepDescription,
  getTimelineStepState,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Pencil, Plus } from '@revolut/icons'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { useCanViewMeetingsTab } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { NominateForPromotion } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/NominateForPromotion'
import { ViewNominationButton } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/PerformanceTimeline/components/ViewNominationButton'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { Statuses } from '@src/interfaces'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'
import { useHasNewScorecards } from '@src/utils/performance'
import { useCanNominateAnyTime } from '@src/utils/promotion'

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: Plus,
  [ButtonStates.Edit]: Pencil,
  [ButtonStates.Continue]: Pencil,
}

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  performanceLink?: string
}

export const PerformanceTimeline = ({ data, selectedPeriod, performanceLink }: Props) => {
  const canAdd = useSelector(canAddTeamKpi)
  const canViewMeetings = useCanViewMeetingsTab(data)
  const user = useSelector(selectUser)
  const showNewScorecards = useHasNewScorecards()
  const canNominateAnyTime = useCanNominateAnyTime()

  const { data: timelineEvents, isLoading } = useGetPerformanceCycleTimelineEvents(
    data.id,
    selectedPeriod.id,
  )

  if (isLoading) {
    return <SectionLoader />
  }

  if (!timelineEvents) {
    return null
  }

  const onAddGoalsClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL), {
      initialValues: getKPIFormInitialValues(
        {
          id: data.id,
          name: data.full_name,
          team: {
            id: data.team.id,
            name: data.team.name,
          },
        },
        { is_employee: true },
      ),
    })
  }

  const renderButton = (event: SingleTimelineEventInterface, eventState: string) => {
    if (eventState !== 'pending') {
      if (event.category === 'promotion') {
        return (
          <ProgressStep.Side>
            {canNominateAnyTime && (
              <NominateForPromotion data={data} cycleId={selectedPeriod.id} />
            )}
            <ViewNominationButton data={data} cycleId={selectedPeriod.id} />
          </ProgressStep.Side>
        )
      }
      return null
    }

    if (event.category === 'kpi' && canAdd) {
      return (
        <ProgressStep.Side>
          <Button variant="secondary" size="sm" useIcon={Plus} onClick={onAddGoalsClick}>
            Add goals
          </Button>
        </ProgressStep.Side>
      )
    }

    if (event.category === 'meeting') {
      return (
        <ProgressStep.Side>
          <Button
            variant="secondary"
            size="sm"
            useIcon={Plus}
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE, {
                  userId: data.id,
                }),
              )
            }}
          >
            Add note
          </Button>
        </ProgressStep.Side>
      )
    }

    if (event.category === 'promotion') {
      return (
        <ProgressStep.Side>
          <NominateForPromotion data={data} cycleId={selectedPeriod.id} />
          <ViewNominationButton data={data} cycleId={selectedPeriod.id} />
        </ProgressStep.Side>
      )
    }

    return null
  }

  const renderPerformanceReviewers = (
    event: SingleTimelineEventInterface,
    eventState: string,
  ) => {
    if (!event.reviews?.length) {
      return null
    }

    const currentUserReview = event.reviews.find(review => review.reviewer.id === user.id)
    const buttonDisabled = currentUserReview?.can_submit
      ? false
      : eventState !== 'pending' || !currentUserReview

    let state = ButtonStates.Add
    if (currentUserReview?.status === Statuses.completed) {
      state = ButtonStates.Edit
    }
    if (currentUserReview?.status === Statuses.draft) {
      state = ButtonStates.Continue
    }

    const reviewLink = showNewScorecards
      ? ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT
      : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL

    return (
      <ProgressStep.Description>
        <StepReviewersList
          reviews={event.reviews}
          onClickAddReview={e => {
            e.stopPropagation()
            navigateTo(
              pathToUrl(reviewLink, {
                id: currentUserReview?.id,
                employeeId: data.id,
              }),
            )
          }}
          buttonDisabled={buttonDisabled}
          icon={ButtonIcon[state]}
          title={ButtonTitles[state]}
          performanceLink={performanceLink}
        />
      </ProgressStep.Description>
    )
  }

  return (
    <>
      <ProgressSteps variant="vertical-compact">
        {timelineEvents.length > 0 &&
          timelineEvents.map((event, i) => {
            if (event.category === 'meeting' && !canViewMeetings) {
              return null
            }

            const state = getTimelineStepState(
              event.start_period,
              event.end_period,
              event.category,
            )
            const description = getTimelineStepDescription(event)

            return (
              <ProgressStep
                data-testid={`${event.title}--${state}`}
                key={i}
                state={state}
              >
                <ProgressStep.Title>{event.title}</ProgressStep.Title>
                <ProgressStep.Description>{description}</ProgressStep.Description>
                {event.category === 'review' && renderPerformanceReviewers(event, state)}
                {renderButton(event, state)}
              </ProgressStep>
            )
          })}
      </ProgressSteps>
    </>
  )
}
