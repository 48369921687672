import React from 'react'
import { ActionButton } from '@revolut/ui-kit'
import { PUBLIC_ROUTES } from '@src/constants/routes'

export const PreviewButton = () => {
  return (
    <ActionButton
      onClick={() => {
        window.open(PUBLIC_ROUTES.CAREERS.JOB_LIST, '_blank')
      }}
    >
      Preview
    </ActionButton>
  )
}
