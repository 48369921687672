import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import {
  createSpecialisationSimple,
  SimpleSpecialisationCreateInterface,
} from '@src/api/roles'
import { selectUser } from '@src/store/auth/selectors'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

type SelectSpecialisationInterface = {
  name: IdAndName
  owner?: IdAndName
}

type CustomSpecialisationInterface =
  | SimpleSpecialisationCreateInterface
  | SelectSpecialisationInterface

interface CreateRolePopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}
export const CreateRolePopup = ({ open, onSuccess, onClose }: CreateRolePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add new role</Header.Title>
      </Header>

      <LapeForm<SimpleSpecialisationCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createSpecialisationSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateRoleForm
            onSelectExistingRole={role => {
              onSuccess({ id: role.id, name: role.name })
            }}
          />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface CreateRoleFormProps {
  onSelectExistingRole: (role: IdAndName) => void
}
const CreateRoleForm = ({ onSelectExistingRole }: CreateRoleFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<CustomSpecialisationInterface>()
  const { validate, forceErrors } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  const isNewSpecialisation =
    values?.name && typeof values.name !== 'string' && !('id' in values?.name)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: currentUser.id, name: currentUser.full_name }
    }
  }, [currentUser, values.owner])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput<{ id: number; name: string; status?: 'archived' }>
          name="name"
          label="Name"
          required
          selector={selectorKeys.specialisations}
          message="We have found roles close to your in our system. Use the default roles to have skills and their classification already created"
          allowCreateNewOption
          onAfterChange={value => {
            if (!value?.id) {
              values.owner = { id: currentUser.id, name: currentUser.full_name }
            }
          }}
          filter={i => i.status !== 'archived'}
        />
        {isNewSpecialisation ? (
          <>
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="seniority_min"
                label="Min Seniority"
                selector={selectorKeys.seniority}
              />
              <LapeRadioSelectInput
                name="seniority_max"
                label="Max Seniority"
                selector={selectorKeys.seniority}
              />
            </InputGroup>
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
          </>
        ) : null}
      </InputGroup>
      <BottomSheet.Actions>
        {isNewSpecialisation ? (
          <Button
            onClick={validate(
              () => submit(),
              error => {
                if (
                  error.response?.status === 400 &&
                  error.response?.data?.non_field_errors?.[0].includes?.(
                    'seniority_min and seniority_max must be provided',
                  )
                ) {
                  forceErrors({
                    seniority_min: !('seniority_min' in values) ? 'Required' : null,
                    seniority_max: !('seniority_max' in values) ? 'Required' : null,
                  })
                } else {
                  statusPopup.show(
                    <StatusPopup variant="error">
                      <StatusPopup.Title>Failed to create a role</StatusPopup.Title>
                      <StatusPopup.Description>
                        {getStringMessageFromError(error)}
                      </StatusPopup.Description>
                    </StatusPopup>,
                  )
                }
              },
            )}
            pending={isSubmitting}
            elevated
          >
            Confirm
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (typeof values.name !== 'string' && 'id' in values.name) {
                onSelectExistingRole(values.name)
              }
            }}
            disabled={!values.name}
          >
            Confirm
          </Button>
        )}
      </BottomSheet.Actions>
    </>
  )
}
