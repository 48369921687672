import {
  JobPostingInterface,
  JobPostingLocationInterface,
  PublishingStatuses,
} from '@src/interfaces/jobPosting'
import { Color } from '@revolut/ui-kit'
import { RevolutTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const getNormalizedLocations = (locations: JobPostingLocationInterface[]) =>
  locations.reduce<{ office: string[]; remote: string[] }>(
    (acc, item) => {
      switch (item.type) {
        case 'remote': {
          acc.remote.push(item.location_name)
          break
        }

        case 'office': {
          acc.office.push(item.location_name)
          break
        }

        default: {
          acc.remote.push(item.location_name)
        }
      }
      return acc
    },
    {
      office: [],
      remote: [],
    },
  )

export const getJobPostingStatusColor = (
  status: PublishingStatuses,
  theme: RevolutTheme,
) => {
  let color

  switch (status) {
    case PublishingStatuses.closed:
      color = Color.GREY_20
      break

    case PublishingStatuses.fully_published:
    case PublishingStatuses.published_internally:
      color = Color.TEAL
      break

    default:
      color = Color.FOREGROUND
  }

  return getColor(theme, color)
}

export const isPublished = (jobPosting: JobPostingInterface) =>
  jobPosting.status !== PublishingStatuses.unpublished &&
  jobPosting.status !== PublishingStatuses.closed

export enum JobDescriptionSections {
  About = 'About the role',
  WhatYouWillBeDoing = "What you'll be doing",
  WhatYouWillNeed = "What you'll need",
  NiceToHave = 'Nice to have',
}

export const useSetJobDescriptionSections = () => {
  const { values } = useLapeContext<JobPostingInterface>()

  useEffect(() => {
    if (!values.sections) {
      // currently we store it on the BE just as json ¯\_(ツ)_/¯
      values.sections = [
        {
          title: JobDescriptionSections.About,
          content: '',
        },
        {
          title: JobDescriptionSections.WhatYouWillBeDoing,
          content: '',
        },
        {
          title: JobDescriptionSections.WhatYouWillNeed,
          content: '',
        },
        {
          title: JobDescriptionSections.NiceToHave,
          content: '',
        },
      ]
    }
  }, [])
}
