import React from 'react'
import {
  jobPostingSettings as jobPostingSettingsRequest,
  useGetJobPostingSettings,
} from '@src/api/settings'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { Avatar, Group, Item, StatusPopup, Switch, useStatusPopup } from '@revolut/ui-kit'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { LinkedinIntegrationButton } from '@src/pages/Settings/JobPosting/LinkedinIntegrationButton'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FormattedMessage } from 'react-intl'

export const CareersIntegrations = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  const { data: jobPostingSettings, refetch } = useGetJobPostingSettings()
  const statusPopup = useStatusPopup()

  return (
    <>
      <NewStepperTitle
        noAutoStep
        title="Job board integrations"
        subtitle={
          <>
            Decide where you want you job postings to be published. <br /> You can enable
            automatic posting of your jobs to external job boards. Once enabled, all
            currently published job postings and all the job posting you will publish in
            the future will be sent to the job board you enabled. Please note that it
            might take up to 24 hours for the job posting to appear on the external
            website.
          </>
        }
      />
      <Group>
        {featureFlags.includes(FeatureFlags.LinkedinIntegration) && (
          <LinkedinIntegrationButton />
        )}
        <Item
          use="label"
          disabled={disableEdit}
          onClick={async e => {
            e.preventDefault()

            try {
              await jobPostingSettingsRequest.update(
                {
                  enable_monster_integration:
                    !jobPostingSettings?.enable_monster_integration,
                },
                // this is how setting endpoints work
                { id: '1' },
              )

              refetch()

              statusPopup.show(
                <StatusPopup variant="success">
                  <StatusPopup.Title>
                    <FormattedMessage
                      id="recruitment.settings.job_postings.monster.success"
                      defaultMessage="Monster integration enabled"
                    />
                  </StatusPopup.Title>
                </StatusPopup>,
              )
            } catch (err) {
              statusPopup.show(
                <StatusPopup variant="success">
                  <StatusPopup.Title>
                    <FormattedMessage
                      id="recruitment.settings.job_postings.monster.fail"
                      defaultMessage="Monster integration failed"
                    />
                  </StatusPopup.Title>
                </StatusPopup>,
              )
            }
          }}
        >
          <Item.Avatar>
            <Avatar size={40} label="M" />
          </Item.Avatar>

          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.title"
                defaultMessage="Monster"
              />
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.description"
                defaultMessage="Enable this integration to let all your published job postings appear on the Monster.com job board."
              />
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Switch checked={!!jobPostingSettings?.enable_monster_integration} />
          </Item.Side>
        </Item>
      </Group>
    </>
  )
}
