import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { LinkedInPosting } from '@src/interfaces/jobPosting'
import { selectorKeys } from '@src/constants/api'
import { upperFirst } from 'lodash'
import { LinkedInPostingStatus } from '@src/pages/Forms/JobPosting/LinkedInPostings/components/LinkedInPostingStatus'
import {
  isPending,
  isRejected,
  isPublished,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/utils'
import { Action, Token } from '@revolut/ui-kit'
import { LinkedInPostingAction } from '@src/pages/Forms/JobPosting/LinkedInPostings/components/LinkedInPostingAction'

type LinkedInPostingColumnInterface = ColumnInterface<LinkedInPosting>

export const linkedInPostingName: LinkedInPostingColumnInterface = {
  type: CellTypes.text,
  idPoint: 'job_posting.name',
  dataPoint: 'job_posting.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const linkedInPostingLocation: LinkedInPostingColumnInterface = {
  type: CellTypes.text,
  idPoint: 'location.location_name',
  dataPoint: 'location.location_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Location',
}

export const linkedInPostingLinkedInLocation: LinkedInPostingColumnInterface = {
  type: CellTypes.text,
  idPoint: 'linkedin_location',
  dataPoint: 'linkedin_location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'LinkedIn Location',
}

export const linkedInPostingType: LinkedInPostingColumnInterface = {
  type: CellTypes.insert,
  idPoint: 'promoted',
  dataPoint: 'promoted',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
  insert: ({ data }) => {
    if (data.is_promoted) {
      return 'Promoted'
    }
    return 'Basic'
  },
}

export const linkedInPostingLink: LinkedInPostingColumnInterface = {
  type: CellTypes.insert,
  idPoint: 'url',
  dataPoint: 'url',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link',
  insert: ({ data }) => {
    if (!data.url) {
      return '-'
    }
    return (
      <Action
        use="a"
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        useIcon="Link"
      >
        Open
      </Action>
    )
  },
}

export const linkedInPostingStatus: LinkedInPostingColumnInterface = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => {
    let descriptionColor = Token.color.greyTone50
    if (isRejected(data)) {
      descriptionColor = Token.color.red
    }
    if (isPending(data)) {
      descriptionColor = Token.color.orange
    }
    if (isPublished(data)) {
      descriptionColor = Token.color.green
    }
    return (
      <LinkedInPostingStatus
        description={upperFirst(data.status)}
        descriptionColor={descriptionColor}
        iconColor={isRejected(data) ? Token.color.red : undefined}
        iconName={isRejected(data) ? 'Question' : undefined}
        tooltipLabel={data.status_detail}
      />
    )
  },
}

export const createLinkedInPostingAction = (
  onSubmit: (data: LinkedInPosting) => void,
): LinkedInPostingColumnInterface => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => {
    return <LinkedInPostingAction linkedInPosting={data} onSubmit={onSubmit} />
  },
})
