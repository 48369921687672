import React, { useState } from 'react'
import { VStack, DataPoint, Flex, Cell, Box } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { fetchGoalGraph, getGoalCommentsAPI, useGetGoal } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { GoalTargetSidebarForm } from '@src/features/Goals/GoalTargetSidebarForm'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import { useGoalTargetsTable } from '@src/features/Goals/common/useGoalTargetsTable'
import { GoalStatusDropdown } from '@src/features/Goals/components/GoalStatusDropdown'
import { TargetsPreviewWidget } from './TargetsPreviewWidget'
import { AnalyticsPreviewWidget } from './AnalyticsPreviewWidget'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { GoalCycleSelectType } from './GoalPreviewPage'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

export const GoalsPreviewLeftSide = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: GoalCycleSelectType
  availableCycles: GoalCycleSelectType[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { data: parent } = useGetGoal(values.parent?.id)
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const roadmapsTable = useGoalRoadmapsTable(cycle)
  const targetsTable = useGoalTargetsTable(cycle)

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id
    onCycleChanged(cycleId)
    targetsTable.onFilterChange(filter)
    roadmapsTable.onFilterChange({ ...filter, columnName: 'review_cycle__id' })
  }
  const cycleFilter = [
    {
      columnName: 'cycle__id',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ] as FilterByInterface[]

  const goalCycles =
    (values.update_type?.id === 'cascaded' ? parent?.goal_cycles : values.goal_cycles) ||
    []

  const goalProgressDetails = goalCycles.find(
    cycleDetails => cycleDetails.review_cycle.id === cycle.id,
  )

  const progress =
    goalProgressDetails?.calibrated_progress ??
    goalProgressDetails?.progress ??
    values.calibrated_progress ??
    values.progress

  return (
    <VStack space="s-24">
      <Cell width="100%">
        <VStack width="100%">
          <Flex width="100%" gap="s-24">
            <GoalCycleFilter
              onFilterChange={onCycleChange}
              columnName="cycle__id"
              filter={cycleFilter}
              selector={() =>
                Promise.resolve({
                  options: availableCycles as unknown as OptionInterface[],
                })
              }
            />
            <OverallProgress value={progress} id={values.id} fetchData={fetchGoalGraph} />
            <DataPoint>
              <DataPoint.Value aria-labelledby="goalStatus">
                <GoalStatusDropdown
                  labelVariant="h5"
                  goal={values}
                  onStatusChanged={status => {
                    values.status = status
                  }}
                />
              </DataPoint.Value>
              <DataPoint.Label id="goalStatus">Status</DataPoint.Label>
            </DataPoint>
          </Flex>
          <Box mx="-s-16">
            <FormPreview data={values}>
              {!!values.parent && (
                <FormPreview.Item
                  title="Parent"
                  field="parent.name"
                  href={() =>
                    pathToUrl(
                      isOnboardingPath()
                        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
                        : ROUTES.FORMS.GOAL.PREVIEW,
                      {
                        id: String(values.parent?.id),
                      },
                    )
                  }
                />
              )}
              {!!values.description && (
                <FormPreview.Details
                  title="Description"
                  field="description"
                  type="showMore"
                />
              )}
            </FormPreview>
          </Box>
        </VStack>
      </Cell>
      {values.update_type?.id === 'target_based' && (
        <TargetsPreviewWidget
          onSelected={setSelectedTarget}
          loading={targetsTable.loading}
        />
      )}
      {!!roadmapsTable.data?.length && (
        <RoadmapsWidget viewMode table={roadmapsTable} variant="preview" />
      )}
      {values.dashboards?.length ? <AnalyticsPreviewWidget /> : null}
      <GoalsCommentsSection goalId={values.id} />
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          targetsTable.refresh()
          roadmapsTable.refresh()
          setSelectedTarget(undefined)
        }}
      />
    </VStack>
  )
}

function GoalsCommentsSection({ goalId }: { goalId: number }) {
  const api = getGoalCommentsAPI(goalId)

  return <CommentsSection api={api} />
}
