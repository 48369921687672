import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  IconButton,
  StatusPopup,
  TableWidget,
  Token,
  usePrevious,
  useStatusPopup,
} from '@revolut/ui-kit'
import { cloneDeep, get, isEqual } from 'lodash'
import { css } from 'styled-components'
import { QueryObserverResult } from 'react-query'

import { TableNames } from '@src/constants/table'
import { useTable } from '@src/components/Table/hooks'
import {
  applyImportSession,
  deleteImportSessionRow,
  editImportSessionRow,
  getUploadSessionTable,
  getUploadSessionTableStats,
  useImportSessionBulkItemSelector,
} from '@src/api/bulkDataImport'
import { deleteEditableTableRow, editEditableTableCell, getEditableTable } from './api'
import {
  ImportInterface,
  ImportSessionInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import {
  CellTypes,
  FilterByInterface,
  RowInterface,
  SortByInterface,
} from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { BulkDataImportActionsProps } from '@src/features/BulkDataImport/BulkDataImportActions'
import { arrayErrorsToFormError } from '@src/utils/form'
import EditableTable from '@src/components/Table/EditableTable/EditableTable'
import { ImportState } from '../BulkDataImport/ImportState'
import {
  GenericEditableTableOnBulkChange,
  GenericEditableTableOnChange,
} from './components'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
  SelectionControls,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  CellState,
  stateAttr,
} from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { StatsConfig, useSelectableTableStats } from '@src/components/StatFilters/hooks'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { ApiVersion } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'

const CellCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    cursor: pointer;
    box-shadow: inset 0 0 0 1px ${Token.color.primary};

    > [data-cell-cover]:hover {
      box-shadow: inset 0 0 0 1px ${Token.color.primary};
    }
  }
`

const CellErrorCss = css`
  background-color: ${Token.color.redActionBackground} !important;
  ${CellCss};
`

const ActionColCss = css`
  z-index: initial !important;
`

const actionColumnKey = 'action'
const stateColumnKey = 'state.id'

const statsConfigBeforeApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'errors',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.error,
  },
  {
    key: 'success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

const statsConfigAfterApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'state_failure',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.error,
  },
  {
    key: 'state_success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

export type CommonGenericEditableTableEntity =
  | 'employee'
  | 'team'
  | 'role'
  | 'job'
  | 'candidate'

export interface CommonGenericEditableTableRowOptions {
  onChange: GenericEditableTableOnChange
  onBulkChange: GenericEditableTableOnBulkChange
  refreshTableState: () => void
}

export interface TableActionsProps {
  sessionData?: ImportSessionInterface
  getSelectedItems: () => number[]
  refreshTableState: () => void
  apiEndpoint: string
  someSelected: boolean
}

interface DeleteConfirmationProps {
  open: boolean
  id?: number
  onClose: () => void
  onSuccess: () => void
}

interface CommonGenericEditableTableProps<T> {
  apiEndpoint: string
  tableName: TableNames
  row: (options: CommonGenericEditableTableRowOptions) => RowInterface<ImportInterface<T>>
  tableActions?: (props: TableActionsProps) => React.ReactNode
  entity: CommonGenericEditableTableEntity
  hiddenColumns?: Record<string, boolean>
  filterByInitial?: FilterByInterface[]
  sortByInitial?: SortByInterface[]
  deleteConfirmation?: (props: DeleteConfirmationProps) => React.ReactNode
  isPreview?: boolean
  apiVersion?: ApiVersion
  preselectedItems?: { id: number }[]
  ignoreQueryParams?: string[]
}

interface ExistingEntitiesActionsParams {
  getSelectedItems: () => number[]
  refreshTableState: () => void
  someSelected: boolean
}

/** Use when working with real entity APIs, e.g. GET `/employees`; PATCH/DELETE `/employees/{id}` */
export interface GenericExistingEntitiesTableProps<T>
  extends CommonGenericEditableTableProps<T> {
  variant: 'existingEntities'
  actions?: (params: ExistingEntitiesActionsParams) => React.ReactNode
}

export interface GenericTemporaryEntitiesTableProps<T>
  extends CommonGenericEditableTableProps<T> {
  actions?: (params: BulkDataImportActionsProps) => React.ReactNode
  sessionData: ImportSessionInterface
  refetchSessionData: () => Promise<QueryObserverResult<ImportSessionInterface, Error>>
  disabled?: boolean
  variant: 'temporaryEntities'
}

/** Use when working with temporary entities in bulk standard import flows, e.g. GET `/employeeUploads/{sessionId}/items`; PATCH/DELETE `/employeeUploads/{sessionId}/items/{itemId}` */
export type GenericEditableTableProps<T> =
  | GenericExistingEntitiesTableProps<T>
  | GenericTemporaryEntitiesTableProps<T>

export const GenericEditableTable = <T,>({
  apiEndpoint,
  tableName,
  row,
  tableActions,
  entity,
  hiddenColumns,
  filterByInitial,
  sortByInitial,
  deleteConfirmation,
  isPreview,
  apiVersion,
  preselectedItems,
  ignoreQueryParams,
  ...props
}: GenericEditableTableProps<T>) => {
  const statusPopup = useStatusPopup()

  const [deleteRowState, setDeleteRowState] = useState<{ open: boolean; id?: number }>({
    open: false,
  })
  const [deletePending, setDeletePending] = useState(false)
  const [submitPending, setSubmitPending] = useState(false)
  const [state, setState] = useState(
    props.variant === 'temporaryEntities' ? props.sessionData.state.id : undefined,
  )
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<{ id: number }>>()

  const table = useTable(
    {
      getItems:
        props.variant === 'temporaryEntities'
          ? getUploadSessionTable<T>(apiEndpoint, props.sessionData.id, apiVersion)
          : getEditableTable<T>(apiEndpoint, apiVersion),
      getStats:
        props.variant === 'temporaryEntities'
          ? getUploadSessionTableStats(apiEndpoint, props.sessionData.id, apiVersion)
          : undefined,
    },
    filterByInitial,
    sortByInitial,
    { omitKeys: ignoreQueryParams },
  )

  const previousErrors = usePrevious(table.stats?.errors)

  const tableFilters = useMemo(
    () => filterSortPageIntoQuery(table.sortBy, table.filterBy) as Record<string, string>,
    [table.filterBy, table.sortBy],
  )

  const selectTableControls = useRef<SelectionControls<{ id: number }>>()

  const bulkItemOptions = useImportSessionBulkItemSelector(
    apiEndpoint,
    props.variant === 'temporaryEntities' ? props.sessionData?.id : null,
    tableFilters,
  )

  const isBeforeApply =
    props.variant === 'temporaryEntities' &&
    (props.sessionData.state.id === 'pending' ||
      props.sessionData.state.id === 'ready_for_review')

  const sessionDataStateId =
    props.variant === 'temporaryEntities' && props.sessionData.state.id

  const statFiltersProps = useSelectableTableStats<
    ImportInterface<T>,
    ImportSessionStatsInterface
  >({
    table,
    statsConfig: isBeforeApply ? statsConfigBeforeApply : statsConfigAfterApply,
    columnName: isBeforeApply ? 'errors__isempty' : 'error_message__isnull',
    totalKey: 'total',
  })

  const produceTableData = (
    id: string | number,
    cb: (rowData: ImportInterface<T>) => ImportInterface<T>,
  ) => {
    table.setData(prev => prev.map(r => (r.id === id ? cloneDeep(cb(r)) : r)))
  }

  useEffect(() => {
    if (props.variant === 'existingEntities' || !sessionDataStateId) {
      return undefined
    }

    if (sessionDataStateId !== 'applying') {
      if (state !== sessionDataStateId) {
        table.refresh()
      }
      setState(sessionDataStateId)

      return undefined
    }

    const interval = setInterval(() => {
      table.refresh()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [props.variant, sessionDataStateId, state, table.filterBy])

  useEffect(() => {
    /** If they fixed all the errors, we should clear the filters and show all the rows */
    if (previousErrors && !table.stats?.errors) {
      table.resetFiltersAndSorting()
    }
  }, [table.stats?.errors, previousErrors])

  useEffect(() => {
    if (preselectedItems) {
      const tableDataItems = table.data.map(x => x.id)
      selectTableControls.current?.setSelection(
        preselectedItems.filter(i => tableDataItems.includes(i.id)),
      )
    }
  }, [table.data, preselectedItems])

  const onEditAction = (rowData: ImportInterface<T>, values: Record<string, unknown>) => {
    const initLoadingFields = Object.keys(values).reduce<Record<string, boolean>>(
      (acc, key) => {
        acc[key] = true
        return acc
      },
      {},
    )
    const initErrorFields = Object.keys(values).reduce<Record<string, undefined>>(
      (acc, key) => {
        acc[key] = undefined
        return acc
      },
      {},
    )
    produceTableData(rowData.id, prev => ({
      ...prev,
      data: { ...prev.data, ...values },
      loading: { ...prev.loading, ...initLoadingFields },
      errors: { ...prev.errors, ...initErrorFields },
    }))

    const editAction =
      props.variant === 'temporaryEntities'
        ? editImportSessionRow(
            apiEndpoint,
            props.sessionData.id,
            rowData.id,
            {
              data: { ...rowData.data, ...values },
            },
            apiVersion,
          )
        : editEditableTableCell(apiEndpoint, rowData.id, { ...values }, apiVersion)

    editAction
      .then(response => {
        table.refreshStats()
        produceTableData(rowData.id, prev => {
          if (props.variant === 'temporaryEntities') {
            const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
              (acc, key) => {
                acc[key] = false
                return acc
              },
              {},
            )

            return {
              ...prev,
              ...response.data,
              loading: { ...prev.loading, ...loadingFields },
            }
          }
          if (props.variant === 'existingEntities') {
            const dataFields = Object.keys(values).reduce<Record<string, unknown>>(
              (acc, key) => {
                acc[key] = get(response.data, key)
                return acc
              },
              {},
            )
            const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
              (acc, key) => {
                acc[key] = false
                return acc
              },
              {},
            )
            const errorFields = Object.keys(values).reduce<Record<string, undefined>>(
              (acc, key) => {
                acc[key] = undefined
                return acc
              },
              {},
            )

            return {
              ...prev,
              data: { ...prev.data, ...dataFields },
              loading: { ...prev.loading, ...loadingFields },
              errors: { ...prev.errors, ...errorFields },
            }
          }
          throw new Error('not reachable')
        })
      })
      .catch(error => {
        const fieldsError = Object.keys(values).reduce<Record<string, [string]>>(
          (acc, field) => {
            if (props.variant === 'temporaryEntities') {
              const apiError = get(error, `response.data.${field}`)
              const fieldError =
                typeof apiError === 'string' ? apiError : 'Something went wrong'
              acc[field] = [fieldError]
              return acc
            }
            if (props.variant === 'existingEntities') {
              const errors = arrayErrorsToFormError(error?.response?.data)
              const apiError = get(errors, field)
              const apiNonFieldError = get(errors, 'non_field_errors')
              const detailError = get(errors, 'detail')
              const fieldError =
                typeof apiError === 'string'
                  ? apiError
                  : typeof apiNonFieldError === 'string'
                  ? apiNonFieldError
                  : typeof detailError === 'string'
                  ? detailError
                  : 'Something went wrong'

              acc[field] = [fieldError]
              return acc
            }
            throw new Error('not reachable')
          },
          {},
        )

        const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
          (acc, key) => {
            acc[key] = false
            return acc
          },
          {},
        )

        produceTableData(rowData.id, prev => ({
          ...prev,
          data: { ...prev.data, ...values },
          errors: { ...prev.errors, ...fieldsError },
          loading: { ...prev.loading, ...loadingFields },
        }))
      })
  }

  const onBulkFieldsChange: GenericEditableTableOnBulkChange = (rowId, values) => {
    const rowData = table.data.find(r => r.id === rowId)

    if (rowData) {
      onEditAction(rowData, values)
    }
  }

  const onFieldChange: GenericEditableTableOnChange = (rowId, value, field) => {
    const rowData = table.data.find(r => r.id === rowId)

    if (rowData && !isEqual(get(rowData.data, field), value)) {
      onEditAction(rowData, { [field]: value })
    }
  }

  const refreshTableState = () => {
    if (props.variant === 'temporaryEntities') {
      bulkItemOptions.refetch()
    }
    selectTableControls.current?.resetState()
    table.refresh()
  }

  const tableRow = useMemo(() => {
    const originalRow = row({
      onChange: onFieldChange,
      onBulkChange: onBulkFieldsChange,
      refreshTableState,
    })
    const actionableCells = isPreview
      ? originalRow.cells
      : originalRow.cells.map(c => ({
          ...c,
          wrapperCss: (rowData: ImportInterface<T>) =>
            get(rowData.errors, c.idPoint) ? CellErrorCss : CellCss,
        }))

    return {
      ...originalRow,
      cells: [
        {
          ...getSelectCellConfig(undefined, 55),
        },
        ...actionableCells,
        {
          type: CellTypes.insert,
          idPoint: actionColumnKey,
          dataPoint: 'action',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Actions',
          width: 100,
          insert: ({ data }: { data: ImportInterface<T> }) => {
            return (
              <IconButton
                useIcon="Delete"
                color={Token.color.greyTone50}
                size={16}
                onClick={() => setDeleteRowState({ open: true, id: data.id })}
              />
            )
          },
          wrapperCss: () => ActionColCss,
        },
        {
          type: CellTypes.insert,
          idPoint: stateColumnKey,
          dataPoint: 'state.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'State',
          insert: ({ data }: { data: ImportInterface<T> }) => <ImportState data={data} />,
          width: 150,
        },
      ].filter(Boolean),
    }
  }, [row, onFieldChange, onBulkFieldsChange])

  const onSubmit = async () => {
    if (props.variant === 'existingEntities') {
      return
    }

    setSubmitPending(true)

    try {
      await applyImportSession(apiEndpoint, props.sessionData.id)
      await props.refetchSessionData()

      if (
        props.sessionData.state.id === 'pending' ||
        props.sessionData.state.id === 'ready_for_review'
      ) {
        table.resetFiltersAndSorting()
      }
    } finally {
      setSubmitPending(false)
    }
  }

  const onDeleteConfirm = () => {
    if (deleteRowState.id) {
      setDeletePending(true)

      const deleteAction =
        props.variant === 'temporaryEntities'
          ? deleteImportSessionRow(apiEndpoint, apiVersion)(
              props.sessionData.id,
              deleteRowState.id,
            )
          : deleteEditableTableRow(apiEndpoint, deleteRowState.id, apiVersion)

      deleteAction
        .then(() => {
          if (deleteRowState.id) {
            selectTableControls.current?.onSelect(`${deleteRowState.id}`, false, {
              id: deleteRowState.id,
            })
          }
          table.refresh()
          setDeleteRowState({ open: false })
        })
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to delete</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => {
          setDeletePending(false)
        })
    }
  }

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected && props.variant === 'temporaryEntities') {
      return bulkItemOptions.options?.filter(
        r => !selectedData.excludeListIds.has(`${r}`),
      )
    }

    if (selectedData?.isAllSelected && props.variant === 'existingEntities') {
      return table.data
        ?.map(r => r.id)
        .filter(r => !selectedData.excludeListIds.has(`${r}`))
    }

    return []
  }

  return (
    <>
      <TableWidget>
        {props.variant === 'temporaryEntities' ? (
          <TableWidget.Info>
            <StatFilters {...statFiltersProps} />
          </TableWidget.Info>
        ) : null}
        {tableActions ? (
          <TableWidget.Actions>
            {tableActions({
              sessionData:
                props.variant === 'temporaryEntities' ? props.sessionData : undefined,
              getSelectedItems,
              refreshTableState,
              apiEndpoint,
              someSelected: !!selectedData?.someSelected,
            })}
          </TableWidget.Actions>
        ) : null}
        <TableWidget.Table>
          <SelectTableWrapper<{ id: number }>
            enabled={!isPreview}
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            onControlsLoaded={controls => {
              selectTableControls.current = controls
            }}
          >
            <EditableTable
              name={tableName}
              {...table}
              useWindowScroll
              onChange={() => {}}
              enableSettings={false}
              lockFirstColumn={isPreview}
              lockLastColumn={!isPreview}
              rowHeight={isPreview ? 'small' : 'medium'}
              row={tableRow}
              hiddenCells={{
                select: isPreview,
                [actionColumnKey]:
                  isPreview ||
                  (props.variant === 'temporaryEntities' ? props.disabled : false),
                [stateColumnKey]:
                  isPreview ||
                  (props.variant === 'temporaryEntities' ? !props.disabled : true),
                ...hiddenColumns,
              }}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      {props.variant === 'temporaryEntities'
        ? props.actions?.({
            onSubmit,
            submitPending,
            sessionData: props.sessionData,
            disableActions: !table.stats || table.stats.errors > 0,
          })
        : props.actions?.({
            getSelectedItems,
            refreshTableState,
            someSelected: !!selectedData?.someSelected,
          }) || null}

      {deleteConfirmation?.({
        ...deleteRowState,
        onClose: () => setDeleteRowState({ open: false }),
        onSuccess: refreshTableState,
      }) || (
        <ConfirmationDialog
          open={deleteRowState.open}
          onClose={() => setDeleteRowState({ open: false })}
          onConfirm={onDeleteConfirm}
          loading={deletePending}
          onReject={() => setDeleteRowState({ open: false })}
          yesMessage="Confirm"
          noMessage="Cancel"
          body={`Are you sure you want to delete this ${entity}?`}
        />
      )}
    </>
  )
}
