import React from 'react'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import {
  onboardingCandidatesV2CountryColumn,
  onboardingCandidatesV2EmailColumn,
  onboardingCandidatesV2JobTitleColumn,
  onboardingCandidatesV2NameColumn,
  onboardingCandidatesV2RecruiterColumn,
  onboardingCandidatesV2RoleColumn,
  onboardingCandidatesV2SeniorityColumn,
} from '@src/constants/columns/importCandidatesV2'
import { CandidateSimpleInterface } from '@src/interfaces/importCandidates'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<CandidateSimpleInterface>> => ({
  cells: [
    {
      ...onboardingCandidatesV2NameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingCandidatesV2EmailColumn(options.onChange),
      width: 120,
    },
    {
      ...onboardingCandidatesV2RoleColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2CountryColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2SeniorityColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2RecruiterColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingCandidatesV2JobTitleColumn(options.onChange),
      width: 200,
    },
  ],
})

const baseFieldsForBulkEdit = ['full_name', 'email']

const fieldsForBulkEdit = {
  team: [...baseFieldsForBulkEdit, 'team'],
  specialisation: [...baseFieldsForBulkEdit, 'specialisation', 'seniority'],
  seniority: [...baseFieldsForBulkEdit, 'specialisation', 'seniority'],
  country: [...baseFieldsForBulkEdit, 'country'],
  recruiter: [...baseFieldsForBulkEdit, 'recruiter'],
  job_posting: [...baseFieldsForBulkEdit, 'job_posting'],
}

export const CandidatesImport = () => {
  return (
    <GenericEditableTable
      apiEndpoint={API.CANDIDATES_SIMPLE}
      apiVersion="v2"
      tableName={TableNames.ImportCandidatesV2}
      row={row}
      entity="candidate"
      variant="existingEntities"
      hiddenColumns={{ action: true }}
      tableActions={props => (
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.NEW}
            useIcon="Plus"
          >
            Add candidate
          </MoreBar.Action>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.IMPORT}
            useIcon="Upload"
          >
            Import candidates
          </MoreBar.Action>
          <BulkEditExistingEntitiesAction
            sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
            buttonIcon="RepairTool"
            field="specialisation"
            label="role"
            selector={selectorKeys.specialisations}
            {...props}
            apiEndpoint={API.CANDIDATES_UPLOAD}
            fieldsForBulkEdit={fieldsForBulkEdit.specialisation}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
            buttonIcon="ArrowRightLeft"
            field="seniority"
            selector={selectorKeys.seniority}
            {...props}
            apiEndpoint={API.CANDIDATES_UPLOAD}
            fieldsForBulkEdit={fieldsForBulkEdit.seniority}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
            buttonIcon="MapPoint"
            field="country"
            selector={selectorKeys.countries}
            {...props}
            apiEndpoint={API.CANDIDATES_UPLOAD}
            fieldsForBulkEdit={fieldsForBulkEdit.country}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
            buttonIcon="Profile"
            field="recruiter"
            selectorField="email"
            selector={selectorKeys.all_employees_avatar_email}
            {...props}
            apiEndpoint={API.CANDIDATES_UPLOAD}
            fieldsForBulkEdit={fieldsForBulkEdit.recruiter}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
            buttonIcon="Document"
            field="job_posting"
            label="job posting"
            selector={selectorKeys.job_postings}
            {...props}
            apiEndpoint={API.CANDIDATES_UPLOAD}
            fieldsForBulkEdit={fieldsForBulkEdit.job_posting}
          />
        </MoreBar>
      )}
    />
  )
}
