import React, { useEffect, useRef, useState } from 'react'
import {
  ActionButton,
  Avatar,
  BottomSheet,
  Box,
  Button,
  Checkbox,
  Header,
  HStack,
  Icon,
  Input,
  Item,
  Link,
  Popup,
  Switch,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  enableLinkedinPromotedJobs,
  getLinkedinApiKey,
  jobPostingSettings as jobPostingSettingsRequest,
  useGetJobPostingSettings,
} from '@src/api/settings'
import { FormattedMessage } from 'react-intl'
import { LinkedinIntegrationResponseInterface } from '@src/interfaces/settings'
import {
  LINKEDIN_INTEGRATION_INSTRUCTIONS,
  LINKEDIN_TC,
} from '@src/constants/externalLinks'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    confirmJobPoster?: (integrations: LinkedinIntegrationResponseInterface[]) => void
  }
}

const LINKEDIN_WIDGET_ID = 'linkedin-widget'

export const LinkedinIntegrationButton = () => {
  const { hash } = useLocation()
  const labelRef = useRef<HTMLLabelElement>(null)
  const { data: jobPostingSettings, refetch } = useGetJobPostingSettings()

  const [state, setState] = useState<
    | {
        type:
          | 'companyId'
          | 'widget'
          | 'success'
          | 'successDisable'
          | 'error'
          | 'errorDisable'
          | 'confirmSwitchOn'
          | 'confirmSwitchOff'
          | undefined
        error?: React.ReactNode
      }
    | undefined
  >()
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState<number>()
  const [tcAccepted, setTcAccepted] = useState(false)

  useEffect(() => {
    if (jobPostingSettings?.linkedin_company_id) {
      // even if we have a value there, override it
      setCompanyId(jobPostingSettings.linkedin_company_id)
    }
  }, [jobPostingSettings?.linkedin_company_id])

  useEffect(() => {
    if (hash === '#linkedInIntegration' && jobPostingSettings && labelRef.current) {
      labelRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      window.history.replaceState('', document.title, window.location.pathname)
    }
  }, [hash, jobPostingSettings])

  const onEnablePremiumPostings = async (
    integrations: LinkedinIntegrationResponseInterface[],
  ) => {
    const data = integrations?.[0]
    if (!data) {
      setState({
        type: 'error',
        error: (
          <FormattedMessage
            id="recruitment.settings.job_postings.linkedin.popup.errorWidget"
            defaultMessage="LinkedIn Premium Jobs Widget did not return a valid response"
          />
        ),
      })
    }

    try {
      await enableLinkedinPromotedJobs(data)

      setState({ type: 'success' })
    } catch (e) {
      setState({
        type: 'error',
        error: (
          <FormattedMessage
            id="recruitment.settings.job_postings.linkedin.popup.errorSystem"
            defaultMessage="Our system could not connect LinkedIn Premium Jobs feature"
          />
        ),
      })
    }
  }

  const onPremiumConnect = async () => {
    const resp = await getLinkedinApiKey()

    if (!resp.data.client_id) {
      throw new Error('No access token found')
    }

    const apikey = resp.data.client_id

    // docs: https://learn.microsoft.com/en-us/linkedin/talent/job-postings/api/job-posting-customer-configuration
    const linkedinWidgetScript = document.createElement('script')
    linkedinWidgetScript.id = LINKEDIN_WIDGET_ID
    linkedinWidgetScript.src = 'https://platform.linkedin.com/xdoor/scripts/in.js'
    linkedinWidgetScript.innerHTML = `
     api_key: "${apikey}"
     extensions: "UJPWidget@https://platform.linkedin.com/rsc/extensions/ujp-onboarding-widget"
    `
    document.head.appendChild(linkedinWidgetScript)

    window.confirmJobPoster = onEnablePremiumPostings

    linkedinWidgetScript.onload = () => {
      setState({ type: 'widget' })
    }
  }

  const onClose = () => {
    document.getElementById(LINKEDIN_WIDGET_ID)?.remove()
    setState(undefined)
  }

  const onEnableIntegration = async () => {
    setLoading(true)

    try {
      if (!companyId) {
        throw new Error('No company ID specified')
      }

      await jobPostingSettingsRequest.update(
        {
          enable_linkedin_integration: true,
          linkedin_company_id: +companyId,
        },
        // this is how setting endpoints work
        { id: '1' },
      )

      refetch()
      onClose()
    } catch (e) {
      setState({
        type: 'error',
        error: e?.response?.data?.linkedin_company_id?.[0] || e?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  const disableIntegration = async () => {
    setLoading(true)

    try {
      await jobPostingSettingsRequest.update(
        {
          enable_linkedin_integration: false,
        },
        // this is how setting endpoints work
        { id: '1' },
      )

      setState({ type: 'successDisable' })
      refetch()
    } catch (e) {
      setState({
        type: 'errorDisable',
        error: e?.response?.data?.enable_linkedin_integration,
      })
    } finally {
      setLoading(false)
    }
  }

  const type = state?.type

  const renderTitle = () => {
    if (!type) {
      return null
    }

    switch (type) {
      case 'confirmSwitchOff':
        return (
          <FormattedMessage
            id="recruitment.settings.job_postings.linkedin.popup.title.disconnect"
            defaultMessage="Disconnect LinkedIn"
          />
        )

      case 'success':
      case 'successDisable':
      case 'errorDisable':
      case 'error':
        return null

      default:
        return (
          <FormattedMessage
            id="recruitment.settings.job_postings.linkedin.popup.title.connect"
            defaultMessage="Connect LinkedIn"
          />
        )
    }
  }

  return (
    <>
      <Popup
        variant="modal-view"
        open={!!type}
        onClose={onClose}
        size={state?.type === 'widget' ? 'md' : 'sm'}
      >
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>{renderTitle()}</Header.Title>
        </Header>
        {type === 'confirmSwitchOn' && (
          <>
            <Box>
              <Text>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.confirmSwitchOn"
                  defaultMessage="Once you switch the integration on we will publish all job postings to your company LinkedIn page."
                />
                <br />
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.wantProceed"
                  defaultMessage="Do you want to proceed?"
                />
              </Text>
            </Box>
            <BottomSheet.Actions horizontal>
              <Button variant="secondary" onClick={onClose}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.cancel"
                  defaultMessage="No, cancel"
                />
              </Button>
              <Button elevated onClick={() => setState({ type: 'companyId' })}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.proceed"
                  defaultMessage="Yes, proceed"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'confirmSwitchOff' && (
          <>
            <Box>
              <Text>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.confirmSwitchOff"
                  defaultMessage="Once you switch the integration off we will unpublish all job postings in your company LinkedIn page."
                />
                <br />
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.wantProceed"
                  defaultMessage="Do you want to proceed?"
                />
              </Text>
            </Box>
            <BottomSheet.Actions horizontal>
              <Button variant="secondary" onClick={onClose}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.cancel"
                  defaultMessage="No, cancel"
                />
              </Button>
              <Button
                elevated
                onClick={disableIntegration}
                pending={loading}
                disabled={loading}
              >
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.proceed"
                  defaultMessage="Yes, proceed"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'companyId' && (
          <>
            <Box>
              <Input
                type="number"
                label="Company ID"
                value={companyId}
                onChange={e => setCompanyId(+e.currentTarget.value)}
              />
            </Box>
            <HStack gap="s-6" mt="s-8">
              <Icon name="InfoOutline" size={18} color={Token.color.blue} />
              <Text>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.instructions"
                  defaultMessage="Follow {link} to locate the company id of your organisation on LinkedIn"
                  values={{
                    link: (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={LINKEDIN_INTEGRATION_INSTRUCTIONS}
                      >
                        <FormattedMessage
                          id="recruitment.settings.job_postings.linkedin.popup.instruction_link"
                          defaultMessage="the instruction"
                        />
                      </Link>
                    ),
                  }}
                />
              </Text>
            </HStack>
            <Widget p="s-16" mt="s-16">
              <VStack gap="s-8">
                <Text>
                  <FormattedMessage
                    id="recruitment.settings.job_postings.linkedin.popup.tc"
                    defaultMessage="By posting your job to LinkedIn, you agree to {link}"
                    values={{
                      link: (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={LINKEDIN_TC}
                        >
                          <FormattedMessage
                            id="recruitment.settings.job_postings.linkedin.popup.tc_link"
                            defaultMessage="LinkedIn’s Jobs Terms & Condition"
                          />
                        </Link>
                      ),
                    }}
                  />
                </Text>
                <Checkbox
                  checked={tcAccepted}
                  onChange={() => setTcAccepted(prev => !prev)}
                >
                  <Text>
                    <FormattedMessage
                      id="recruitment.settings.job_postings.linkedin.popup.tc_agree"
                      defaultMessage="I agree"
                    />
                  </Text>
                </Checkbox>
              </VStack>
            </Widget>

            <BottomSheet.Actions horizontal>
              <Button
                elevated
                pending={loading}
                disabled={loading || !companyId || !tcAccepted}
                onClick={onEnableIntegration}
              >
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.connect"
                  defaultMessage="Connect"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'widget' && (
          <Box minHeight={380}>
            <script
              type="IN/UJPWidget"
              data-onconfirmjobposter="confirmJobPoster"
              data-width="720px"
            />
          </Box>
        )}
        {type === 'success' && (
          <>
            <VStack gap="s-16" align="center">
              <Icon name="104/Success" size={42} color={Token.color.green} />
              <Text variant="h5" textAlign="center">
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.successEnabled"
                  defaultMessage="Integration successfully enabled"
                />
              </Text>
            </VStack>
            <BottomSheet.Actions horizontal>
              <Button elevated onClick={onClose}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.continue"
                  defaultMessage="Continue"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'successDisable' && (
          <>
            <VStack gap="s-16" align="center">
              <Icon name="104/Success" size={42} color={Token.color.green} />
              <Text variant="h5" textAlign="center">
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.successDisabled"
                  defaultMessage="Integration successfully disabled"
                />
              </Text>
            </VStack>

            <BottomSheet.Actions horizontal>
              <Button elevated onClick={onClose}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.close"
                  defaultMessage="Close"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'error' && (
          <>
            <VStack gap="s-16" align="center">
              <Icon name="104/Error" size={62} color={Token.color.red} />
              <Text variant="h5" textAlign="center">
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.failed"
                  defaultMessage="Integration failed"
                />
              </Text>
              <Text textAlign="center">{state!.error}</Text>
            </VStack>

            <BottomSheet.Actions horizontal>
              <Button elevated onClick={() => setState({ type: 'companyId' })}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.try"
                  defaultMessage="Try again"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
        {type === 'errorDisable' && (
          <>
            <VStack gap="s-16" align="center">
              <Icon name="104/Error" size={42} color={Token.color.red} />
              <Text variant="h5" textAlign="center">
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.cannotDisable"
                  defaultMessage="Cannot disable the Linkedin integration"
                />
              </Text>
              <Text textAlign="center">{state!.error}</Text>
            </VStack>
            <BottomSheet.Actions horizontal>
              <Button elevated onClick={disableIntegration}>
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.popup.try"
                  defaultMessage="Try again"
                />
              </Button>
            </BottomSheet.Actions>
          </>
        )}
      </Popup>
      <Item
        ref={labelRef}
        use="label"
        onClick={e => {
          e.preventDefault()

          setState({
            type: jobPostingSettings?.enable_linkedin_integration
              ? 'confirmSwitchOff'
              : 'confirmSwitchOn',
          })
        }}
      >
        <Item.Avatar>
          <Avatar useIcon="LogoLinkedIn" />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="recruitment.settings.job_postings.linkedin.title"
              defaultMessage="LinkedIn"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="recruitment.settings.job_postings.linkedin.description"
              defaultMessage="Automatically publish your jobs to your company LinkedIn page"
            />
            {jobPostingSettings?.linkedin_company_id && (
              <>
                <br />
                <Text>Company id: {jobPostingSettings?.linkedin_company_id}</Text>
                <br />
                <Text>
                  Premium job postings:{' '}
                  {jobPostingSettings?.linkedin_contract_id ? 'enabled' : 'disabled'}
                </Text>
              </>
            )}
          </Item.Description>
          {jobPostingSettings?.enable_linkedin_integration &&
            jobPostingSettings?.linkedin_company_id && (
              <ActionButton
                mt="s-12"
                useIcon="Gear"
                onClick={e => {
                  e.stopPropagation()
                  onPremiumConnect()
                }}
              >
                <FormattedMessage
                  id="recruitment.settings.job_postings.linkedin.premiumSettings"
                  defaultMessage="Premium job postings settings"
                />
              </ActionButton>
            )}
        </Item.Content>
        <Item.Side>
          <Switch checked={!!jobPostingSettings?.enable_linkedin_integration} />
        </Item.Side>
      </Item>
    </>
  )
}
