import React, { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
} from 'styled-system'
import styled from 'styled-components'
import { isFloat, isInt, isPositiveInt } from '@src/utils/string'
import { roundFloat } from '@src/utils/numbers'

const Input = styled.input<LayoutProps & ColorProps & BorderProps>`
  ${layout}
  ${color}
  ${border}
  padding: 0;
`

export enum TableCellInputType {
  int = 'int',
  positiveInt = 'positiveInt',
  float = 'float',
  positiveFloat = 'positiveFloat',
  text = 'text',
  percent = 'percent',
}

export interface TableCellInputProps {
  value?: string | number
  type: TableCellInputType
  disabled?: boolean
  placeholder?: string
  onChange?: (value: string | number) => void
  onBlur?: (value: string | number) => void
  cleanZeroOnFocus?: boolean
  defaultToZero?: boolean
}

// A place where you write a validator for your type
export const inputChecksByType = {
  [TableCellInputType.int]: (value: string) => isInt(value),
  [TableCellInputType.float]: (value: string) => isFloat(value, 2),
  [TableCellInputType.positiveInt]: (value: string) => isPositiveInt(value),
  [TableCellInputType.positiveFloat]: (value: string) => isFloat(value, 2, true),
  [TableCellInputType.text]: () => true,
  [TableCellInputType.percent]: (value: string) => {
    return Number(Number(value) <= 100) && isFloat(value, 2, true)
  },
}

export const parseValueByType = {
  [TableCellInputType.int]: (value: string) => parseInt(value, 10),
  [TableCellInputType.float]: (value: string) => roundFloat(value, 2),
  [TableCellInputType.positiveInt]: (value: string) => Math.abs(parseInt(value, 10)),
  [TableCellInputType.positiveFloat]: (value: string) => Math.abs(roundFloat(value, 2)),
  [TableCellInputType.text]: (value: string) => value,
  [TableCellInputType.percent]: (value: string) => Math.abs(roundFloat(value, 2)),
}

const TableCellInput = ({
  value = '',
  type,
  placeholder,
  disabled,
  onChange = () => {},
  onBlur = () => {},
  cleanZeroOnFocus = false,
  defaultToZero = false,
}: TableCellInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  // Guys to validate your types please use inputChecksByTypes
  // Do not add any if statements here
  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    e => {
      const newValue = e.currentTarget.value

      // Handle negative numbers
      if (
        newValue === '-' &&
        [TableCellInputType.float, TableCellInputType.int].includes(type)
      ) {
        onChange(newValue)
      }

      if (newValue !== '' && !inputChecksByType[type](newValue)) {
        return
      }

      onChange(String(newValue))
    },
    [type],
  )

  const handleBlur: FormEventHandler<HTMLInputElement> = useCallback(e => {
    const val = cleanZeroOnFocus
      ? e.currentTarget.value || String(0)
      : e.currentTarget.value

    if (
      defaultToZero &&
      [
        TableCellInputType.float,
        TableCellInputType.int,
        TableCellInputType.positiveFloat,
        TableCellInputType.positiveInt,
        TableCellInputType.percent,
      ].includes(type) &&
      val === ''
    ) {
      onBlur('0')
      return
    }

    if (inputChecksByType[type](val)) {
      onBlur(parseValueByType[type](val))
    }
  }, [])

  return (
    <Input
      data-testid="table-cell-input"
      ref={inputRef}
      value={cleanZeroOnFocus && value === 0 ? '' : value}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleBlur(e)
        }
      }}
      disabled={disabled}
      width="100%"
      height="100%"
      bg="transparent"
      border="none"
    />
  )
}

export default TableCellInput
