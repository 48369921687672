import { Avatar, Group, Item, VStack } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import React from 'react'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Bug, Gear, LockClosed, LogoutDoor, Profile, SendMessage } from '@revolut/icons'
import { setFeedbackOpen } from '@src/store/feedback/actions'
import { logOutAction } from '@src/store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import {
  generalSettings,
  organisationSettings,
  peopleSettings,
  performanceSettings,
  plansAndBilling,
} from '@src/pages/Settings/SettingsLandingPage/SettingsLandingPage'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import AppVersion from '@src/features/AppVersion/AppVersion'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useSubsciptionInfo } from '@src/api/plans'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { SUPPORT_REVOLUT_PEOPLE } from '@src/constants/emails'
import { REVOLUTERS_BUG_REPORT } from '@src/constants/externalLinks'

const Preferences = () => {
  const dispatch = useDispatch()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()
  const subscriptionInfo = useSubsciptionInfo()
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canRaiseTickets = featureFlags?.includes(FeatureFlags.TicketsApplication)

  const keyPersonsEnabled = !isCommercial

  const canViewAdmin = [
    ...peopleSettings(engagement_enabled, keyPersonsEnabled),
    ...organisationSettings,
    ...performanceSettings,
    ...plansAndBilling(subscriptionInfo),
    ...generalSettings(featureFlags?.includes(FeatureFlags.TenantAccounts)),
  ]
    .reduce<PermissionTypes[]>((prev, curr) => [...prev, ...(curr.canView || [])], [])
    .some(permission => permissions.includes(permission))

  return (
    <PageWrapper>
      <PageHeader title="User settings" backUrl={ROUTES.MAIN} />
      <PageBody>
        <VStack gap="s-16">
          {canViewAdmin ? (
            <Group>
              <Item
                variant="disclosure"
                type="button"
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.ALL)}
              >
                <Item.Avatar>
                  <Avatar useIcon={Gear} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Admin panel</Item.Title>
                </Item.Content>
              </Item>
            </Group>
          ) : null}

          <Group>
            <Item
              variant="disclosure"
              type="button"
              use={InternalLink}
              to={ROUTES.SETTINGS.USER_PREFERENCES}
            >
              <Item.Avatar>
                <Avatar useIcon={Profile} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>User preferences</Item.Title>
              </Item.Content>
            </Item>
            <HideIfCommercial>
              <Item
                variant="disclosure"
                type="button"
                use={InternalLink}
                to={pathToUrl(ROUTES.PREFERENCES.PRIVACY)}
              >
                <Item.Avatar>
                  <Avatar useIcon={LockClosed} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Data & privacy policies</Item.Title>
                </Item.Content>
              </Item>
            </HideIfCommercial>
            <Item
              {...(canRaiseTickets
                ? {
                    use: 'button',
                    onClick: () => {
                      bugReportSideState.open = true
                    },
                  }
                : {
                    use: 'a',
                    href: isCommercial
                      ? `mailto:${SUPPORT_REVOLUT_PEOPLE}`
                      : REVOLUTERS_BUG_REPORT,
                    target: isCommercial ? '_self' : '_blank',
                  })}
              variant="disclosure"
            >
              <Item.Avatar>
                <Avatar useIcon={Bug} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Report a bug</Item.Title>
              </Item.Content>
            </Item>
            <Item
              use="button"
              onClick={() => {
                dispatch(setFeedbackOpen(true))
              }}
              variant="disclosure"
            >
              <Item.Avatar>
                <Avatar useIcon={SendMessage} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Leave feedback</Item.Title>
              </Item.Content>
            </Item>
          </Group>
          <Group>
            <Item
              use="button"
              onClick={() => {
                dispatch(logOutAction())
              }}
            >
              <Item.Avatar>
                <Avatar useIcon={LogoutDoor} color="red" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Logout</Item.Title>
              </Item.Content>
            </Item>
          </Group>
          <AppVersion />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export default Preferences
