import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { GoalsSettingsAll } from './All'
import { GoalsSettingsTargetTypes } from './TargetTypes'
import { GoalsSettingsApprovalProcesses } from './ApprovalProcesses'

export interface GoalsSettingsProps {
  allRoute: string
  targetTypesRoute: string
  approvalProcessRoute: string
  backUrl: string
  isOnboarding: boolean
}

export const GoalsSettings = (props: GoalsSettingsProps) => {
  return (
    <Switch>
      <Route path={props.allRoute}>
        <GoalsSettingsAll {...props} />
      </Route>
      <Route path={props.targetTypesRoute}>
        <GoalsSettingsTargetTypes {...props} />
      </Route>
      <Route path={props.approvalProcessRoute}>
        <GoalsSettingsApprovalProcesses {...props} />
      </Route>
    </Switch>
  )
}
