import React from 'react'
import { Avatar, AvatarProps, Box, Item, ItemProps } from '@revolut/ui-kit'
import { ChevronRight } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@components/CommonSC/Tooltip'

export interface NewSelectionCardProps extends ItemProps {
  title: string
  subtitle?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  disabledTooltip?: React.ReactNode
  icon: AvatarProps['useIcon']
}

const NewSelectionCard = (props: NewSelectionCardProps) => {
  const { title, subtitle, onClick, icon, disabled, use, disabledTooltip, ...itemProps } =
    props
  return (
    <Tooltip
      placement="bottom"
      body={<TooltipContainer>{disabledTooltip}</TooltipContainer>}
      hide={!disabled || !disabledTooltip}
    >
      <Box width="100%">
        <Item
          onClick={onClick}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          use={disabled ? 'button' : use}
          disabled={disabled}
          {...itemProps}
        >
          <Item.Avatar>
            <Avatar useIcon={icon} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{title}</Item.Title>
            {subtitle ? <Item.Description>{subtitle}</Item.Description> : null}
          </Item.Content>
          <Item.Side>
            <ChevronRight color="grey-tone-20" />
          </Item.Side>
        </Item>
      </Box>
    </Tooltip>
  )
}

export default NewSelectionCard
