import React from 'react'

import {
  CommonGenericEditableTableRowOptions,
  TableActionsProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'

export const baseFieldsForBulkEdit = ['team', 'specialisation', 'seniority'] as const
export type BaseFieldsForBulkEdit = typeof baseFieldsForBulkEdit[number]

export type CreateCallback = (
  type: CreateNewItemType,
  employeeId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

export type Row = (
  onCreate: CreateCallback,
  mode: EditableTableRenderMode,
) => (
  options: CommonGenericEditableTableRowOptions,
) => RowInterface<ImportInterface<EmployeesSimpleInterface>>

export type BaseTableProps = {
  row: Row
  sessionRoute: string
  hiddenColumns?: Record<string, boolean>
}

export type EditableEmployeesTableProps = BaseTableProps & {
  bulkSessionFieldsMap: Record<string, string[]>
  isPreview?: boolean
  previewActions?: React.ReactNode
  renderEditActionsLeft?: (props: TableActionsProps) => React.ReactNode
  renderEditActionsRight?: (props: TableActionsProps) => React.ReactNode
  useMainAddForm?: boolean
}

export type BulkEmployeeUploadFlowProps = BaseTableProps & {
  getHeader: (title?: string) => React.ReactNode
  importRoute: string
  anyRoute: string
  apiEndpoint: string
  renderMoreActions?: (props: TableActionsProps) => React.ReactNode
  onAfterConfirmRoute?: string
  headerTitle?: string
  visibleActions: Record<string, boolean>
}

export type BulkSessionTableWrapperProps = Omit<
  BulkEmployeeUploadFlowProps,
  'row' | 'headerTitle' | 'visibleActions'
>

export type CreateNewItemType =
  | 'team'
  | 'role'
  | 'companyEntity'
  | 'location'
  | 'department'

export const getBulkSessionHeaderTitle = (type?: BaseFieldsForBulkEdit) => {
  switch (type) {
    case 'team':
      return 'Review bulk assigned team'
    case 'specialisation':
      return 'Review bulk assigned role'
    case 'seniority':
      return 'Review bulk assigned seniority'
    default:
      return 'Import employees'
  }
}

export const baseBulkEditFieldsDependencies: Record<
  BaseFieldsForBulkEdit,
  BaseFieldsForBulkEdit[]
> = {
  team: ['team'],
  specialisation: ['specialisation', 'seniority'],
  seniority: ['specialisation', 'seniority'],
}
